<template>
  <el-dialog
    v-bind="{
      title: '原材料检验详情-' + alloName,
      width: '1200'
    }"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-table :data="tableData" border >
      <el-table-column label="报告编号" prop="reptNo"></el-table-column>
      <el-table-column label="生产企业" prop="prodName"></el-table-column>
      <el-table-column label="委托单位" prop="entrName"></el-table-column>
      <el-table-column label="质检单位" prop="inspFirm"></el-table-column>
      <el-table-column label="成品批号" prop="batchNo"></el-table-column>
      <el-table-column label="生产日期" prop="prodDat" min-width="90"></el-table-column>
      <el-table-column label="检验日期" prop="inspDate" min-width="110"></el-table-column>
      <el-table-column label="样品名称" prop="sampName"></el-table-column>
      <el-table-column label="型号规格" prop="model"></el-table-column>
      <el-table-column label="检测项目" prop="testItem"></el-table-column>
      <el-table-column label="单位" prop="measUnit"></el-table-column>
      <el-table-column label="技术要求" prop="techRequ"></el-table-column>
      <el-table-column label="检验结果" prop="singlResult"></el-table-column>
      <el-table-column label="单项评价" prop="singlEvalu"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import {
  dataQumaReptDetail
} from '@/apis/quality/materialsQualityTest'
export default {
  props: {
    visible: { type: Boolean, default: false },
    editData: { type: Object, default: () => {} }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () { return this.visible }
    }
  },
  data () {
    return {
      alloName: '',
      submitForm: {
        annual: '',
        reptNo: '', // 报告编号
        starInspDat: '',
        batchNo: '',
        checType: '',
        endInspDat: '',
        entrName: '',
        entrRelaId: '',
        firstQumaReptId: '',
        inspFirm: '',
        inspRelaId: '',
        prodCompId: '',
        prodDat: '',
        prodName: '',
        qumaAlloId: '',
        qumaReptId: '',
        remark: '',
        reptClauDtoList: [
          // {
          //   qumaReptId: '',
          //   alloName: '',
          //   sampName: '',
          //   model: '',
          //   varieties: '',
          //   measUnit: '',
          //   singlEvalu: '',
          //   singlResult: '',
          //   techRequ: '',
          //   testItem: ''
          // }
        ]
      },
      tableData: []
    }
  },
  methods: {
    // 打开弹窗
    async handlerOpen () {
      if (this.editData) {
        this.loading = true
        const res = await dataQumaReptDetail({ qumaReptId: this.editData.qumaReptId })
        if (res.code == 200) {
          this.loading = false
          const data = res.data
          const reptClauDtoList = data.reptClauDtoList
          delete data.reptClauDtoList
          this.alloName = reptClauDtoList[0] ? reptClauDtoList[0].alloName : ''
          this.tableData = reptClauDtoList.map((item) => {
            return {
              ...item,
              ...data,
              prodDat: data.prodDat.slice(0, 10),
              inspDate: data.starInspDat.slice(0, 10) + ' - ' + data.endInspDat.slice(0, 10)
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      }
    },

    // 关闭弹窗
    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
  }
}
</style>
