<template>
  <el-dialog
    v-bind="{
      title: this.editData ? '编辑原材料检验报告' : '新增原材料检验报告',
      width: '1024px'
    }"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form
      ref="form"
      :model="submitForm"
      :rules="rules"
      inline
      label-width="110px"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="报告编号" prop="reptNo">
            <el-input v-model="submitForm.reptNo" class="w-200"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="质检单模板" prop="qumaAlloId">
            <el-select v-model="submitForm.qumaAlloId" @change="tmplChange" class="w-200">
              <el-option v-for="item in qualityTmpls" :value="item.qumaAlloId" :label="item.alloName" :key="item.qumaAlloId" />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="生产企业名称" prop="prodCompId">
            <el-select v-model="submitForm.prodCompId" filterable class="w-200">
              <el-option v-for="item in comps" :value="item.compId" :label="item.compName" :key="item.compId" />
            </el-select>
            <!-- <el-input v-model="submitForm.prodName" class="w-200"></el-input> -->
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="8">
          <el-form-item label="所属年份" prop="annual">
            <el-date-picker
              v-model="submitForm.annual"
              type="year"
              value-format="yyyy"
              placeholder="选择年"
              class="w-200">
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="类型" prop="checType">
            <el-radio v-model="submitForm.checType" :disabled="Boolean(editData)" @change="typeChange" :label="1">初检</el-radio>
            <el-radio v-model="submitForm.checType" :disabled="Boolean(editData)" @change="typeChange" :label="2">复检</el-radio>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item v-if="submitForm.checType==2" label="对应初检报告" prop="firstQumaReptId">
            <el-select v-model="submitForm.firstQumaReptId" clearable>
              <el-option v-for="item in reptNos" :value="item.qumaReptId" :label="item.reptNo" :key="item.qumaReptId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>

        <el-col :span="8">
          <el-form-item label="质检单位" prop="inspRelaId">
            <el-select v-model="submitForm.inspRelaId" filterable class="w-200">
              <el-option v-for="item in trustComps" :value="item.compId" :label="item.compName" :key="item.compId" />
            </el-select>
            <!-- <el-autocomplete
              v-model.trim="submitForm.entrName"
              :fetch-suggestions="queryEntr"
              clearable
              class="w-200"
            ></el-autocomplete> -->
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <!-- <el-form-item label="质检单位" prop="inspFirm"> -->
          <el-form-item label="委托单位" prop="entrName">
            <el-input v-model="submitForm.entrName" disabled class="w-200"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="8">
          <el-form-item label="成品批号" prop="batchNo">
            <el-input v-model="submitForm.batchNo" class="w-200"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="生产日期" prop="prodDat">
            <el-date-picker
              v-model="submitForm.prodDat"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              class="w-200">
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="检验日期" prop="starInspDat" label-width="95px">
            <el-date-picker
              v-model="testDate"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              class="w-220"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-form-item label="备注" prop="remark">
          <el-input class="w-860" type="textarea" v-model="submitForm.remark"></el-input>
        </el-form-item>
      </el-row>

      <el-table :data="submitForm.reptClauDtoList" border class="mt-15">
        <el-table-column label="样品名称" prop="sampName"></el-table-column>
        <el-table-column label="型号规格" >
          <template v-slot="{row, $index}">
            <el-form-item :prop="'reptClauDtoList.' + $index + '.model'" :rules="rules.require">
              <el-input v-model="row.model"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="检测项目" prop="testItem"></el-table-column>
        <el-table-column label="品种" prop="varieties"></el-table-column>
        <el-table-column label="单位" prop="measUnit"></el-table-column>
        <el-table-column label="技术要求">
          <template v-slot="{row, $index}">
            <el-form-item :prop="'reptClauDtoList.' + $index + '.techRequ'" :rules="rules.require">
              <el-input v-model="row.techRequ"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="检测结果">
          <template v-slot="{row, $index}">
            <el-form-item :prop="'reptClauDtoList.' + $index + '.singlResult'" :rules="rules.require">
              <el-input v-model="row.singlResult"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="单项评价" >
          <template v-slot="{row, $index}">
            <el-form-item :prop="'reptClauDtoList.' + $index + '.singlEvalu'" :rules="rules.require">
              <el-select v-model="row.singlEvalu">
                <el-option value="合格" label="合格"></el-option>
                <el-option value="不合格" label="不合格"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getQumaAlloByType,
  qumaAlloDetail
} from '@/apis/quality/qualityTestConfig'
import {
  addOrUpdateDataQumaRept,
  // getRelaListByRelaType,
  dataQumaReptDetail,
  getQumaReptListByChecType
} from '@/apis/quality/materialsQualityTest'
import { getComps, gianComplist } from '@/apis/common'
import { configTypes } from '@/views/qualityManage/concreteQuality/qualityTestConfig/util'
import { mapState } from 'vuex'
export default {
  props: {
    visible: { type: Boolean, default: false },
    editData: { type: Object, default: () => {} }
  },
  computed: {
    ...mapState('account', ['userInfo']),
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () { return this.visible }
    },
    testDate: {
      get () {
        const submitForm = this.submitForm
        if (submitForm.starInspDat && submitForm.endInspDat) {
          const startDate = submitForm.starInspDat
          const endDate = submitForm.endInspDat
          return [startDate, endDate]
        } else {
          return null
        }
      },
      set (newVal) {
        const submitForm = this.submitForm
        const [startDate, endDate] = newVal || ['', '']
        submitForm.starInspDat = startDate
        submitForm.endInspDat = endDate
      }
    }
  },
  watch: {
    'submitForm.prodCompId' (prodCompId) {
      // 生产企业名称
      const comps = this.comps
      const submitForm = this.submitForm
      const targetComps = comps.find(item => item.compId == prodCompId) || { compName: '' }
      submitForm.prodName = targetComps.compName
    },

    'submitForm.inspRelaId' (inspRelaId) {
      // 获取质检名称
      const trustComps = this.trustComps
      const submitForm = this.submitForm
      const targetRustComps = trustComps.find(item => item.compId == inspRelaId) || { compName: '' }
      submitForm.inspFirm = targetRustComps.compName
    }
  },
  data () {
    return {
      configTypes,
      qualityTmpls: [],
      // testComps: [], // 质检单位
      trustComps: [], // 委托单位
      comps: [],
      reptNos: [], // 报告编号列表
      submitForm: {
        qumaReptId: '', // 原材料质检报告id
        reptNo: '', // 报告编号
        qumaAlloId: '', // 质检单模板id
        prodName: '', // 生产企业名称
        prodCompId: '', // 生产企业流水号
        annual: '', // 所属年份
        checType: 1, // 检验类型 1:初检 2:复检
        entrName: '', // 委托单位
        entrRelaId: '', // 委托单位流水号
        inspFirm: '', // 质检单位
        inspRelaId: '', // 质检单位流水号
        batchNo: '', // 成品批次
        prodDat: '', // 生产日期
        starInspDat: '', // 开始检验日期
        endInspDat: '', // 结束检验日期
        remark: '', // 备注
        firstQumaReptId: '', // 对应报告编号Id
        firstReptNo: '', // 对应报告编号
        reptClauDtoList: [
          // {
          //   qumaReptId: '', // 材料质检单报告流水号
          //   alloName: '', // 质检单模板名称
          //   sampName: '', // 样品名称
          //   model: '', // 型号规格
          //   testItem: '', // 检测项目
          //   varieties: '', // 品种
          //   measUnit: '', // 计量单位
          //   techRequ: '', // 技术要求
          //   singlResult: '', // 单项检验结果
          //   singlEvalu: '合格' // 单项检验评价
          // }
        ]
      },
      rowTmpl: {
        qumaAlloId: '',
        testItem: '', // 检测项目
        measUnit: '', // 计量单位
        techRequ: '' // 技术要求
      },
      rules: {
        require: { required: true, trigger: 'change', message: '请输入' },
        reptNo: [
          { required: true, trigger: 'change', message: '请输入' },
          { validator: this.checkReptNo, trigger: 'change' }
        ],
        qumaAlloId: { required: true, trigger: 'change', message: '请输入' },
        annual: { required: true, trigger: 'change', message: '请输入' },
        batchNo: { required: true, trigger: 'change', message: '请输入' },
        prodDat: { required: true, trigger: 'change', message: '请输入' },
        starInspDat: { required: true, trigger: 'change', message: '请输入' }
      },
      loading: false
    }
  },
  created () {
    this.initComps()
    this.initTestTmpl()
    // this.initTestComps()
    this.initTrustComps()
  },
  methods: {
    checkReptNo (rule, value, callback) {
      const reptNos = this.reptNos
      const firstQumaReptId = this.submitForm.firstQumaReptId
      const targetNoObj = reptNos.find(({ qumaReptId }) => qumaReptId == firstQumaReptId) || { reptNo: undefined }
      if (targetNoObj.reptNo == value) {
        callback(new Error('报告编号 与 对应初检编号 不能相同'))
      } else {
        callback()
      }
    },
    typeChange (checType) {
      if (checType == 1) {
        this.submitForm.firstQumaReptId = ''
        this.submitForm.firstReptNo = ''
      }
    },
    async tmplChange (qumaAlloId) {
      if (qumaAlloId) {
        const res = await qumaAlloDetail({ qumaAlloId })
        if (res.code == 200) {
          const data = res.data
          this.submitForm.reptClauDtoList = res.data.alloClauList.map((item) => {
            const newItem = {
              qumaReptId: '', // 材料质检单报告流水号
              alloName: data.alloName,
              model: data.model,
              sampName: data.sampName,
              varieties: data.varieties,
              measUnit: item.measUnit,
              techRequ: item.techRequ,
              testItem: item.testItem,
              singlResult: '', // 单项检验结果
              singlEvalu: '合格' // 单项检验评价
            }
            return newItem
          })
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    async initComps () {
      const res = await gianComplist({
        compType: 40,
        pageSize: 9999
      })
      if (res.code == 200) {
        this.comps = res.data.rows
      } else {
        this.$message.error(res.msg)
      }
    },

    async initTestTmpl () {
      const res = await getQumaAlloByType({ type: 1 })
      if (res.code === 200) {
        this.qualityTmpls = res.data
      } else {
        this.$message.error(res.msg)
      }
    },

    async initTrustComps () {
      const res = await gianComplist({
        compType: 50,
        pageSize: 9999
      })
      if (res.code == 200) {
        this.trustComps = res.data.rows
      } else {
        this.$message.error(res.msg)
      }
    },

    async initReptNos () {
      // 获取报告编号列表
      const res = await getQumaReptListByChecType({ checType: 1 })
      if (res.code === 200) {
        this.reptNos = res.data
      } else {
        this.$message.error(res.msg)
      }
    },

    queryTest (keywords, cb) {
      const results = this.testComps
      cb(results.filter(item => item.value.includes(keywords)))
    },

    queryEntr (keywords, cb) {
      const results = this.trustComps
      cb(results.filter(item => item.value.includes(keywords)))
    },

    addRow () {
      const rowTmpl = { ...this.rowTmpl }
      this.submitForm.alloClauList.push(rowTmpl)
    },

    deleteRow (index) {
      this.submitForm.alloClauList.splice(index, 1)
    },

    // 打开弹窗
    async handlerOpen () {
      this.initReptNos()
      const submitForm = this.submitForm
      if (this.editData) {
        this.loading = true
        const res = await dataQumaReptDetail({ qumaReptId: this.editData.qumaReptId })
        if (res.code == 200) {
          this.loading = false
          const data = res.data
          submitForm.annual = data.annual + ''
          submitForm.batchNo = data.batchNo
          submitForm.checType = data.checType
          submitForm.entrName = data.entrName
          // 质检单位
          submitForm.inspFirm = data.inspFirm
          submitForm.inspRelaId = data.inspRelaId
          submitForm.prodName = data.prodName
          submitForm.prodCompId = data.prodCompId
          submitForm.prodDat = data.prodDat
          submitForm.qumaReptId = data.qumaReptId
          submitForm.qumaAlloId = data.qumaAlloId
          submitForm.remark = data.remark
          submitForm.reptNo = data.reptNo
          submitForm.reptClauDtoList = data.reptClauDtoList
          submitForm.starInspDat = data.starInspDat
          submitForm.endInspDat = data.endInspDat
          submitForm.firstQumaReptId = data.firstQumaReptId
        } else {
          this.$message.error(res.msg)
        }
      } else {
        // 新建获取质检单位，编辑不需要获取
        const { compId } = this.userInfo
        if (compId) {
          const { data: { rows: comps = [{}] } } = await getComps({ compId })
          //           entrName: '', // 委托单位
          // entrRelaId: '', // 委托单位流水号
          submitForm.entrName = comps[0].compName
          submitForm.entrRelaId = comps[0].compId
        }
      }
    },

    // 关闭弹窗
    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
    },

    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const submitForm = JSON.parse(JSON.stringify(this.submitForm))
          // 报告编号
          if (submitForm.firstQumaReptId) {
            const reptNos = this.reptNos
            const targetTestComps = reptNos.find((item) => item.qumaReptId == submitForm.firstQumaReptId) || { reptNo: undefined }
            submitForm.firstReptNo = targetTestComps.reptNo
          } else {
            submitForm.firstQumaReptId = undefined
            submitForm.firstReptNo = undefined
          }
          this.loading = true

          addOrUpdateDataQumaRept(submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('操作成功！')
              this.$emit('refresh')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
  }
}
</style>
