import http from '@/utils/http'

// 获取原材料检验报告列表
export function getDataQumaReptList (p) {
  return http.get('/material/qualityManage/getDataQumaReptList', p)
}

// 新增或编辑原材料检验报告
export function addOrUpdateDataQumaRept (p) {
  return http.post('/material/qualityManage/addOrUpdateDataQumaRept', p)
}

// 获取 委托单位 和 质检单位
export function getRelaListByRelaType (p) {
  return http.get('/material/qualityManage/getRelaListByRelaType', p)
}

// 获取编辑详情
export function dataQumaReptDetail (p) {
  return http.get('/material/qualityManage/dataQumaReptDetail', p)
}

// 获取复检编号 checType 检验类型 1:初检 2:复检
export function getQumaReptListByChecType (p) {
  return http.get('/material/qualityManage/getQumaReptListByChecType', p)
}

// 刪除原材料检验报告
export function deleteDataQumaRept (p) {
  return http.post('/material/qualityManage/deleteDataQumaRept', p)
}

// 导出原材料检验报告
export function exportDataQumaRept (p) {
  return http.postBlob('/material/qualityManage/exportDataQumaRept', p)
}

// 导出模板
export function exportDataQumaReptDemo (p) {
  return http.postBlob('/material/qualityManage/exportDataQumaReptDemo', p)
}

// 导入
export function importDataQumaRept (p) {
  return http.postForm('/material/qualityManage/importDataQumaRept', p)
}

// 编辑页面 生产企业列表，参数 relaType 为 70
export function getRelaList (p) {
  return http.post('/unibu/proj/getRelaList', p)
}
